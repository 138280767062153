var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "编辑描述",
        visible: _vm.modelDialog,
        "custom-class": "model-dialog",
        width: "600px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.modelDialog = $event
        },
        close: _vm.handleClose,
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.formInline,
            rules: _vm.rules,
            "label-width": "100px",
            "label-position": "right",
          },
        },
        [
          _c(
            "el-form-item",
            {
              attrs: { label: _vm.$t("searchModule.Rule_Name"), prop: "title" },
            },
            [
              _c("el-input", {
                attrs: { disabled: "" },
                model: {
                  value: _vm.formInline.title,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.formInline,
                      "title",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "formInline.title",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "规则描述", prop: "descMsg" } },
            [
              _c("el-input", {
                attrs: {
                  type: "textarea",
                  placeholder: "请输入规则描述",
                  autosize: { minRows: 2, maxRows: 16 },
                  "show-word-limit": "",
                  maxlength: "600",
                },
                model: {
                  value: _vm.formInline.descMsg,
                  callback: function ($$v) {
                    _vm.$set(_vm.formInline, "descMsg", $$v)
                  },
                  expression: "formInline.descMsg",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.handleClose } }, [
            _vm._v("取 消"),
          ]),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.loading },
              on: { click: _vm.submitData },
            },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }